*, :after, :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

.hidden {
    display: none !important;
}

body, html {
    height: 100%
}

html {
    font-size: 14px
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-width: 375px;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, .87);
    font-smoothing: antialiased;
    background: #e7eae6;
    background: linear-gradient(135deg, #0978e3 0, #e80097 100%)
}

body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px
}

body ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .1);
    border-radius: 0
}

body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, .25);
    -webkit-transition: color .2s ease;
    transition: color .2s ease
}

body ::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 135, 139, .8)
}

body .ui.inverted::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .1)
}

body .ui.inverted::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .25)
}

body .ui.inverted::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, .35)
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

strong {
    font-weight: inherit;
    font-weight: bolder
}

img {
    border-style: none
}

h2 {
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    line-height: 1.28571429em;
    margin: calc(2rem - .14285714em) 0 1rem;
    font-weight: 700;
    padding: 0
}

h2 {
    font-size: 1.71428571rem
}

h2:first-child {
    margin-top: 0
}

p {
    margin: 0 0 1em;
    line-height: 1.4285em
}

p:last-child {
    margin-bottom: 0
}

a {
    color: #1073b9;
    text-decoration: none
}

a:hover {
    color: #0a518c;
    text-decoration: none
}

::-webkit-selection {
    background-color: #cce2ff;
    color: rgba(0, 0, 0, .87)
}

::-moz-selection {
    background-color: #cce2ff;
    color: rgba(0, 0, 0, .87)
}

::selection {
    background-color: #cce2ff;
    color: rgba(0, 0, 0, .87)
}

h2 {
    font-size: 1.5rem
}

.ui.dropdown:focus, :focus, a:focus {
    outline: 1px dotted hsla(0, 0%, 100%, .5)
}

img {
    max-width: 100%
}

i.icon {
    padding: 0;
    background: 0;
    color: inherit;
    text-shadow: inherit;
    line-height: inherit;
    white-space: inherit;
    vertical-align: inherit;
    border-radius: inherit
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.ui.dropdown {
    vertical-align: top !important
}

.ui.container {
    display: block;
    max-width: 100% !important
}

#header {
    background: #21667a
}

.ui.nav.inverted.menu {
    background: #0c3247;
    transition: top 1s, opacity 1s;
    top: 0
}

.ui.nav .ui.container {
    width: 100%;
    height: 4rem;
    margin-left: 0 !important;
    transition: width .25s, margin .25s
}

.ui.nav .item {
    padding: .92857143rem
}

.ui.nav .item>img.logo {
    width: 100px;
    margin-top: 0
}

.ui.nav.menu .ui.dropdown .menu a.item img {
    opacity: .75;
    height: 1rem
}

.ui.divider {
    margin: 1rem 0;
    line-height: 1;
    height: 0;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    color: rgba(0, 0, 0, .85);
    user-select: none;
    -webkit-tap-highlight-color: transparent
}

.ui.divider:not(.vertical):not(.horizontal) {
    border-top: 1px solid rgba(34, 36, 38, .15);
    border-bottom: 1px solid hsla(0, 0%, 100%, .1)
}

.ui.divider {
    font-size: 1rem
}

.ui.header {
    border: none;
    margin: calc(2rem - .14285714em) 0 1rem;
    padding: 0;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 1.28571429em;
    text-transform: none;
    color: rgba(0, 0, 0, .87)
}

.ui.header:first-child {
    margin-top: -.14285714em
}

.ui.header+p {
    margin-top: 0
}

h2.ui.header {
    font-size: 1.5rem
}

i.inverted.icon {
    color: #fff
}

i.icon {
    font-size: 1em
}

i.large.icon {
    line-height: 1;
    vertical-align: middle;
    font-size: 1.5em
}

i.icon.sign.in:before {
    content: "\F2F6"
}

i.icon.sign.out:before {
    content: "\F2F5"
}

i.icon.circle:before {
    content: "\F111"
}

i.icon.heart:before {
    content: "\F004"
}

i.icon.question:before {
    content: "\F128"
}

i.icon.question.circle:before {
    content: "\F059"
}

i.icon.shield:before {
    content: "\F3ED"
}

i.icon.phone:before {
    content: "\f095"
}

i.icon.calendar:before {
    content: "\f133"
}

i.icon.arrows.alternate.horizontal:before {
    content: "\f337"
}

i.icon.check.circle:before {
    content: "\f058"
}

i.icon.info.circle:before {
    content: "\f05a"
}

i.icon.dropdown:before {
    content: "\F0D7"
}

i.icon.outline {
    font-family: outline-icons
}

i.icon.circle.outline:before {
    content: "\F111"
}

i.icon.question.circle.outline:before {
    content: "\F059"
}

.ui.image {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    background-color: transparent
}

img.ui.image {
    display: block;
}

.ui.floated.image {
    float: left;
    margin-right: 1em;
    margin-bottom: 1em
}

.ui.right.floated.image {
    float: right;
    margin-right: 0;
    margin-bottom: 1em;
    margin-left: 1em
}

.ui.mini.image {
    width: 35px;
    height: auto;
    font-size: .78571429rem
}

.ui.segment {
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
    margin: 1rem 0;
    padding: 1em;
    border-radius: .28571429rem;
    border: 1px solid rgba(34, 36, 38, .15)
}

.ui.segment:first-child {
    margin-top: 0
}

.ui.segment:last-child {
    margin-bottom: 0
}

.ui.segment {
    font-size: 1rem
}

.ui.menu .item>a .icon {
    line-height: 1
}

.ui.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 0;
    margin: -1rem
}

.ui.grid>.column:not(.row) {
    position: relative;
    display: inline-block;
    width: 6.25%;
    padding-left: 1rem;
    padding-right: 1rem;
    vertical-align: top
}

.ui.grid>* {
    padding-left: 1rem;
    padding-right: 1rem
}

.ui.grid>.column:not(.row) {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.ui[class*="equal width"].grid>.column:not(.row) {
    display: inline-block;
    flex-grow: 1
}

@media only screen and (max-width:1150px) {
    .ui.stackable.grid>.column:not(.row) {
        width: 50% !important;
        margin: 0 !important;
        box-shadow: none !important;
        padding: 1rem !important
    }
}

@media only screen and (max-width:959px) {
    .ui.stackable.grid {
        width: auto;
        margin-left: 0 !important;
        margin-right: 0 !important
    }
    .ui.stackable.grid>.column:not(.row) {
        width: 100% !important;
        margin: 0 !important;
        box-shadow: none !important;
        padding: 1rem !important
    }
}

.ui.menu {
    display: flex;
    margin: 1rem 0;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    background: #fff;
    font-weight: 400;
    border: 1px solid rgba(34, 36, 38, .15);
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
    border-radius: .28571429rem;
    min-height: 2.85714286em
}

.ui.menu:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden
}

.ui.menu:first-child {
    margin-top: 0
}

.ui.menu:last-child {
    margin-bottom: 0
}

.ui.menu .menu {
    margin: 0
}

.ui.menu:not(.vertical) .item {
    display: flex;
    align-items: center
}

.ui.menu .item {
    position: relative;
    vertical-align: middle;
    line-height: 1;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    flex: 0 0 auto;
    user-select: none;
    background: 0 0;
    padding: .92857143em 1.14285714em;
    text-transform: none;
    color: rgba(0, 0, 0, .87);
    font-weight: 400;
    transition: background .1s ease, box-shadow .1s ease, color .1s ease
}

.ui.menu .item:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: rgba(34, 36, 38, .1)
}

.ui.menu .item>a:not(.ui) {
    user-select: text;
    line-height: 1.3
}

.ui.menu .item>i.icon {
    opacity: .9;
    float: none;
    margin: 0 .35714286em 0 0
}

.ui.menu>.container {
    display: flex;
    align-items: inherit;
    flex-direction: inherit
}

.ui.menu .header.item {
    margin: 0;
    background: "";
    text-transform: normal;
    font-weight: 700
}

.ui.menu .item>i.dropdown.icon {
    padding: 0;
    float: right;
    margin: 0 0 0 1em
}

.ui.menu .dropdown.item .menu {
    min-width: calc(100% - 1px);
    border-radius: 0 0 .28571429rem .28571429rem;
    background: #fff;
    margin: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .08);
    flex-direction: column !important
}

.ui.menu .ui.dropdown .menu>.item {
    margin: 0;
    text-align: left;
    font-size: 1em !important;
    padding: .78571429em 1.14285714em !important;
    background: 0 0 !important;
    color: rgba(0, 0, 0, .87) !important;
    text-transform: none !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    transition: none !important
}

.ui.menu .ui.dropdown .menu>.item:hover {
    background: rgba(0, 0, 0, .05) !important;
    color: rgba(0, 0, 0, .95) !important
}

.ui.menu .ui.dropdown.item .menu .item:not(.filtered) {
    display: block
}

.ui.menu .ui.dropdown .menu>.item .icon:not(.dropdown) {
    display: inline-block;
    font-size: 1em !important;
    float: none;
    margin: 0 .75em 0 0 !important
}

.ui.menu .item>img:not(.ui) {
    display: inline-block;
    vertical-align: middle;
    margin: -.3em 0;
    width: 2.5em
}

@media only screen and (max-width:767px) {
    .ui.menu>.ui.container {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important
    }
}

@media only screen and (min-width:768px) {
    .ui.menu:not(.secondary):not(.text):not(.tabular):not(.borderless)>.container>.item:not(.right):not(.borderless):first-child {
        border-left: 1px solid rgba(34, 36, 38, .1)
    }
}

.ui.menu .dropdown.item:hover, .ui.menu a.item:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, .03);
    color: rgba(0, 0, 0, .95)
}

.ui.menu a.item:active {
    background: rgba(0, 0, 0, .03);
    color: rgba(0, 0, 0, .95)
}

.ui.menu:not(.vertical) .right.menu {
    display: flex;
    margin-left: auto !important
}

.ui.menu .right.menu>.item:before {
    right: auto;
    left: 0
}

.ui.inverted.menu {
    border: 0 solid transparent;
    background: #0c3247;
    box-shadow: none
}

.ui.inverted.menu .item, .ui.inverted.menu .item>a:not(.ui) {
    background: 0 0;
    color: hsla(0, 0%, 100%, .9)
}

.ui.inverted.menu .item:before {
    background: hsla(0, 0%, 100%, .08)
}

.ui.inverted.menu .header.item {
    margin: 0;
    background: 0 0;
    box-shadow: none
}

.ui.inverted.menu .dropdown.item:hover, .ui.inverted.menu a.item:hover {
    background: hsla(0, 0%, 100%, .08);
    color: #fff
}

.ui.inverted.menu a.item:active {
    background: hsla(0, 0%, 100%, .08);
    color: #fff
}

.ui.menu.fixed {
    position: fixed;
    z-index: 101;
    margin: 0;
    width: 100%
}

.ui.menu.fixed, .ui.menu.fixed .item:first-child, .ui.menu.fixed .item:last-child {
    border-radius: 0 !important
}

.ui.fixed.menu {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto
}

.ui.menu {
    font-size: 1rem
}

.ui.nav .ui.dropdown .menu {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .075);
    border: 1px solid rgba(0, 0, 0, .15);
    border-top: 0;
    border-radius: 0 0 .28571429rem .28571429rem
}

.menu i.icon {
    background: 0 !important
}

@media only screen and (max-width:1350px) {
    #header .ui.nav .right.menu .item:before {
        display: none
    }
}

.ui.nav .help.item {
    display: none
}

@media only screen and (min-width:768px) {
    .ui.nav .help.item {
        display: flex
    }
}

.ui.nav .item>.knowledgebase .icon {
    margin: 0;
    color: hsla(0, 0%, 100%, .75);
    transition: color .25s
}

.ui.nav .item>.knowledgebase:focus .icon, .ui.nav .item>.knowledgebase:hover .icon {
    color: #fff
}

.ui.nav .dropdown .menu>.divider {
    width: 100%
}

.ui.nav .ui.dropdown .menu>.item:focus, .ui.nav .ui.dropdown .menu>.item:hover {
    background: rgba(0, 0, 0, .05) !important;
    outline: 0
}

.ui.menu .ui.dropdown .menu>.item {
    max-width: 20rem !important
}

.ui.card {
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 290px;
    min-height: 0;
    background: #fff;
    padding: 0;
    border: none;
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    transition: box-shadow .1s ease, transform .1s ease;
    z-index: ""
}

.ui.card {
    margin: 1em 0
}

.ui.card a {
    cursor: pointer
}

.ui.card:first-child {
    margin-top: 0
}

.ui.card:last-child {
    margin-bottom: 0
}

.ui.card:after {
    display: block;
    content: " ";
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden
}

.ui.card>:first-child {
    border-radius: .28571429rem .28571429rem 0 0 !important;
    border-top: none !important
}

.ui.card>:last-child {
    border-radius: 0 0 .28571429rem .28571429rem !important
}

.ui.card>.content {
    flex-grow: 1;
    border: none;
    border-top: 1px solid rgba(34, 36, 38, .1);
    background: 0 0;
    margin: 0;
    padding: 1em;
    box-shadow: none;
    font-size: 1rem;
    border-radius: 0
}

.ui.card>.content:after {
    display: block;
    content: " ";
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden
}

.ui.card [class*="right floated"] {
    float: right
}

.ui.card .content img {
    display: inline-block;
    vertical-align: middle;
    width: ""
}

.ui.card>.content>.description {
    clear: both;
    color: rgba(0, 0, 0, .68)
}

.ui.card>.content a:not(.ui) {
    color: "";
    transition: color .1s ease
}

.ui.card>.content a:not(.ui):hover {
    color: ""
}

.ui.fluid.card {
    width: 100%;
    max-width: 9999px
}

.ui.card>.content.header {
    padding: 1em;
    max-height: 7rem
}

.ui.card>.content.header .workspace-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 1.28571429rem;
    line-height: 1.71428571rem;
    color: #0c3247;
    display: block
}

.ui.card.drop-in ul {
    padding: 0;
    list-style: none
}

.ui.card.drop-in li {
    border-top: 1px solid #eee
}

.ui.card.drop-in li:last-child {
    border-bottom: 1px solid #eee
}

.ui.dropdown {
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: 0;
    text-align: left;
    transition: box-shadow .1s ease, width .1s ease;
    -webkit-tap-highlight-color: transparent
}

.ui.dropdown .menu {
    cursor: auto;
    position: absolute;
    display: none;
    outline: 0;
    top: 100%;
    min-width: max-content;
    margin: 0;
    padding: 0;
    background: #fff;
    font-size: 1em;
    text-shadow: none;
    text-align: left;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: .28571429rem;
    transition: opacity .1s ease;
    z-index: 11;
    will-change: transform, opacity
}

.ui.dropdown .menu>* {
    white-space: nowrap
}

.ui.dropdown>.dropdown.icon {
    position: relative;
    font-size: .85714286em;
    margin: 0 0 0 1em
}

.ui.dropdown .menu>.item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0, 0, 0, .87);
    padding: .78571429rem 1.14285714rem !important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
    -webkit-touch-callout: none
}

.ui.dropdown .menu>.item:first-child {
    border-top-width: 0
}

.ui.dropdown .menu>.divider {
    border-top: 1px solid rgba(34, 36, 38, .1);
    height: 0;
    margin: .5em 0
}

.ui.dropdown .menu>.item>.icon, .ui.dropdown .menu>.item>img {
    margin-top: 0
}

.ui.dropdown .menu>.item>.icon, .ui.dropdown .menu>.item>img {
    margin-left: 0;
    float: none;
    margin-right: .78571429rem
}

.ui.dropdown .menu>.item>img {
    display: inline-block;
    vertical-align: top;
    width: auto;
    margin-top: -.5em;
    margin-bottom: -.5em;
    max-height: 2em
}

.ui.menu .ui.dropdown .menu>.item:before {
    display: none
}

.ui.menu .right.menu .dropdown:last-child .menu {
    left: auto;
    right: 0
}

.ui.dropdown .menu>.item:hover {
    background: rgba(0, 0, 0, .05);
    color: rgba(0, 0, 0, .95);
    z-index: 13
}

.ui.dropdown .menu {
    left: 0
}

.ui.simple.dropdown .menu:after, .ui.simple.dropdown .menu:before {
    display: none
}

.ui.simple.dropdown .menu {
    position: absolute;
    display: block;
    overflow: hidden;
    top: -9999px !important;
    opacity: 0;
    width: 0;
    height: 0;
    transition: opacity .1s ease
}

.ui.simple.dropdown:hover {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.ui.simple.dropdown:hover>.menu {
    overflow: visible;
    width: auto;
    height: auto;
    top: 100% !important;
    opacity: 1
}

.ui.dropdown>.dropdown.icon {
    font-family: Dropdown;
    line-height: 1;
    height: 1em;
    width: 1.23em;
    backface-visibility: hidden;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    width: auto
}

.ui.dropdown>.dropdown.icon:before {
    content: "\F0D7"
}

[data-tooltip] {
    position: relative
}

[data-tooltip]:before {
    content: "";
    width: 100%;
    padding-top: 100%;
    transform: rotate(45deg);
    z-index: 2;
    box-shadow: 1px 1px 0 0 #bababc
}

[data-tooltip]:after, [data-tooltip]:before {
    pointer-events: none;
    position: absolute;
    font-size: 1rem;
    background: #fff
}

[data-tooltip]:after {
    content: attr(data-tooltip);
    text-transform: none;
    text-align: left;
    white-space: nowrap;
    border: 1px solid #d4d4d5;
    line-height: 1.4285em;
    max-width: none;
    padding: .833em 1em;
    font-weight: 400;
    font-style: normal;
    color: rgba(0, 0, 0, .87);
    border-radius: .28571429rem;
    box-shadow: 0 0 1px 0 rgba(34, 36, 38, .12), 0 1px 2px 0 rgba(34, 36, 38, .15);
    z-index: 1
}

[data-tooltip]:after, [data-tooltip]:before {
    pointer-events: none;
    visibility: hidden
}

[data-tooltip]:before {
    opacity: 0;
    transform: rotate(45deg) scale(0) !important;
    transform-origin: center top;
    transition: all .1s ease
}

[data-tooltip]:after {
    opacity: 1;
    transform-origin: center bottom;
    transition: all .1s ease
}

[data-tooltip]:hover:after, [data-tooltip]:hover:before {
    visibility: visible;
    pointer-events: auto
}

[data-tooltip]:hover:before {
    transform: rotate(45deg) scale(0.4) !important;
    opacity: 1
}

[data-tooltip]:after, [data-tooltip][data-position="bottom center"]:after {
    transform: translateX(-50%) scale(0) !important
}

[data-tooltip]:hover:after, [data-tooltip][data-position="bottom center"]:hover:after {
    transform: translateX(-50%) scale(1) !important
}

[data-position="bottom center"][data-tooltip]:after {
    bottom: auto;
    right: auto;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    margin-top: .5em
}

[data-position="bottom center"][data-tooltip]:before {
    bottom: auto;
    right: auto;
    top: 80%;
    left: 0px;
    margin-left: 0px;
    margin-top: 0px;
}

[data-position~=bottom][data-tooltip]:before {
    background: #fff;
    box-shadow: -2.5px -2.5px 0 0 #bababc
}

[data-position~=bottom][data-tooltip]:before {
    transform-origin: center center
}

[data-position~=bottom][data-tooltip]:after {
    transform-origin: center top
}

.semantic {
    box-sizing: border-box;
    min-width: 700px
}

.ui.nav .right.menu .image {
    border-radius: 50%;
    margin-bottom: 0;
    width: 35px;
    height: 35px;
    object-fit: cover;
    margin-top: -0.714285714285714rem;
}

.ui.nav.menu .container>.item img.icon {
    width: 2em;
    margin-right: .5em
}

.card img.right.floated.icon {
    height: 1.5rem
}

#user_name_display {
    min-width: 14rem;
    text-align: right;
    margin-top: .5rem
}

.ui.nav .right.menu .avatar-initials {
    position: absolute;
    top: 1.357142857142857rem;
    right: 1.15rem;
    text-align: center;
    width: 35px;
    font-family: Lato, sans-serif;
    font-weight: 700
}

.ui.nav .meta {
    font-size: .64285714rem;
    color: #fff !important;
    text-transform: uppercase;
    letter-spacing: .14285714rem;
    white-space: nowrap;
    position: absolute;
    top: 2rem;
    right: 4.3rem
}

.ui.nav.inverted.menu {
    background: #000
}

.ui.inverted.menu .item:before {
    background: hsla(0, 0%, 100%, .2)
}

#header .ui.menu .container>a.item {
    width: 153px
}

#header .ui.menu .container>a.item:first-child {
    width: 135px
}

#header .ui.menu .container>.ui.simple.dropdown.item {
    width: 165px
}

#flex {
    background: #e7eae6;
    display: flex;
    position: relative;
}

.overview {
    background: #031632;
    color: #fff;
    margin: 0;
    border-right: 1px solid #EEE;
    padding: 1rem;
    overflow: auto;
    flex-basis: 33.3%;
    position: relative;
    min-width: 288px;
    transition: flex-basis 1s, max-width 1s, min-width 1s;
}

@media screen and (max-width:1550px) {
    .overview {
        max-width: 453px;
    }
}

.overview-hidden>.overview {
    flex-basis: 3%;
    margin: 0;
    min-width: 4rem;
    overflow: hidden;
}

.overview>.content {
    margin: 1rem;
    margin-left: 1rem;
    transition: opacity 1s, margin-left 1s;
}

.overview-hidden>.overview>.content {
    opacity: 0;
    position: absolute;
}

.overview .ui.image {
    margin: 2rem 0;
    max-width: 100%
}

.overview_toggle {
    color: #4fb4cc;
    transition: color .25s;
    position: absolute;
    right: 1rem;
    font-size: 1.7rem;
    top: 2rem;
    height: 2rem;
    width: 2rem;
    z-index: 1
}

.overview_toggle a:hover {
    color: #fff
}

.overview_toggle a .icon {
    margin: 0
}

.overview .toggle.right, .overview-hidden>.overview .toggle.left {
    display: none
}

.overview .toggle.left, .overview-hidden>.overview .toggle.right {
    display: block
}

i.icon.arrow.circle.left:before {
    content: "\f0a8"
}

i.icon.arrow.circle.right:before {
    content: "\f0a9"
}

.overview .ui.header {
    color: #fff;
    font-weight: 700
}

h4.ui.header {
    margin: 1rem 1rem 0 !important;
    display: block;
}

.portal .ui.card>.content>.partners.description {
    padding: .5rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.partners.description>div {
    flex: 1 0 30%;
    background: rgba(0, 0, 0, .05);
    margin: .5em;
    padding: 1em;
    border-radius: 3px;
    display: flex
}

@media screen and (min-width:1280px) {
    .partners.description>div {
        flex: 1;
    }
}

.partners.description>div>img {
    margin: auto;
    max-height: 60px
}

.portal.page.content {
    padding: 1rem 0 1.5rem;
    flex-basis: 66.6%;
    transition: flex-basis 1s
}

.overview-hidden>.portal.page.content {
    flex-basis: 95%
}

.portal.page.extra.content {
    width: auto;
    margin: 3.5rem auto
}

.portal .segment.portal-elements {
    background: #e1e4e0;
    -webkit-box-shadow: 0 1px 2px 0 inset rgba(34, 36, 38, .15);
    box-shadow: 0 1px 2px 0 inset rgba(34, 36, 38, .15)
}

@media screen and (min-width:768px) {
    .portal .grid .ui.fluid.card {
        min-height: 100%
    }
}

.portal .ui.header span {
    font-weight: 500
}

.portal .ui.card>.content {
    padding: 0
}

.portal.page .ui.card>.content.header {
    max-height: 3.5rem;
    padding: 1rem
}

.portal .ui.card>.content>.description {
    padding: 1em;
    overflow: auto
}

.portal.page.content .ui.stackable.grid {
    margin-left: -1rem !important;
    margin-right: -1rem !important
}

.portal .segment.portal-elements {
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0
}

.ui.card {
    border-radius: 1px
}

.portal.page .ui.card>.content.header {
    border-top: 3px solid #4fb4cc !important;
    border-radius: 0 !important
}

.portal.page .ui.card>.content.header {
    border-color: #2f79dc;
    border-radius: 0 !important;
    border-top: 5px solid !important;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #8231b6, #2f79dc)
}

.portal.page .ui.card>.content.header {
    max-height: 4rem
}

.ui.card>.content.header .workspace-name {
    color: #031632
}

.ui.card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);
    transition: box-shadow .5s
}

.ui.card a.workspace-name::after {
    content: "▶";
    opacity: 0;
    font-size: small;
    margin-left: .5rem;
    transition: opacity .5s;
    color: #4fb4cc
}

.ui.card .workspace-name:hover::after {
    opacity: 1
}

@font-face {
    font-family: Lato;
    src: url(themes/default/assets/fonts/lato-bold-webfont.eot);
    src: url(themes/default/assets/fonts/lato-bold-webfont.eot#iefix) format("embedded-opentype"), url(themes/default/assets/fonts/lato-bold-webfont.woff2) format("woff2"), url(themes/default/assets/fonts/lato-bold-webfont.woff) format("woff"), url(themes/default/assets/fonts/lato-bold-webfont.ttf) format("truetype"), url(themes/default/assets/fonts/lato-bold-webfont.svg#latobold) format("svg");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: Lato;
    src: url(themes/default/assets/fonts/lato-bolditalic-webfont.eot);
    src: url(themes/default/assets/fonts/lato-bolditalic-webfont.eot#iefix) format("embedded-opentype"), url(themes/default/assets/fonts/lato-bolditalic-webfont.woff2) format("woff2"), url(themes/default/assets/fonts/lato-bolditalic-webfont.woff) format("woff"), url(themes/default/assets/fonts/lato-bolditalic-webfont.ttf) format("truetype"), url(themes/default/assets/fonts/lato-bolditalic-webfont.svg#latobold_italic) format("svg");
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: Lato;
    src: url(themes/default/assets/fonts/lato-italic-webfont.eot);
    src: url(themes/default/assets/fonts/lato-italic-webfont.eot#iefix) format("embedded-opentype"), url(themes/default/assets/fonts/lato-italic-webfont.woff2) format("woff2"), url(themes/default/assets/fonts/lato-italic-webfont.woff) format("woff"), url(themes/default/assets/fonts/lato-italic-webfont.ttf) format("truetype"), url(themes/default/assets/fonts/lato-italic-webfont.svg#latoitalic) format("svg");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: Lato;
    src: url(themes/default/assets/fonts/lato-regular-webfont.eot);
    src: url(themes/default/assets/fonts/lato-regular-webfont.eot#iefix) format("embedded-opentype"), url(themes/default/assets/fonts/lato-regular-webfont.woff2) format("woff2"), url(themes/default/assets/fonts/lato-regular-webfont.woff) format("woff"), url(themes/default/assets/fonts/lato-regular-webfont.ttf) format("truetype"), url(themes/default/assets/fonts/lato-regular-webfont.svg#latoregular) format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Icons;
    src: url(themes/default/assets/fonts/icons.eot);
    src: url(themes/default/assets/fonts/icons.eot#iefix) format("embedded-opentype"), url(themes/default/assets/fonts/icons.woff2) format("woff2"), url(themes/default/assets/fonts/icons.woff) format("woff"), url(themes/default/assets/fonts/icons.ttf) format("truetype"), url(themes/default/assets/fonts/icons.svg#icons) format("svg");
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-decoration: inherit;
    text-transform: none
}

@font-face {
    font-family: outline-icons;
    src: url(themes/default/assets/fonts/outline-icons.eot);
    src: url(themes/default/assets/fonts/outline-icons.eot#iefix) format("embedded-opentype"), url(themes/default/assets/fonts/outline-icons.woff2) format("woff2"), url(themes/default/assets/fonts/outline-icons.woff) format("woff"), url(themes/default/assets/fonts/outline-icons.ttf) format("truetype"), url(themes/default/assets/fonts/outline-icons.svg#icons) format("svg");
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-decoration: inherit;
    text-transform: none
}

@font-face {
    font-family: Dropdown;
    src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAVgAA8AAAAACFAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAchGgaq0dERUYAAAF0AAAAHAAAAB4AJwAPT1MvMgAAAZAAAABDAAAAVnW4TJdjbWFwAAAB1AAAAEsAAAFS8CcaqmN2dCAAAAIgAAAABAAAAAQAEQFEZ2FzcAAAAiQAAAAIAAAACP//AANnbHlmAAACLAAAAQoAAAGkrRHP9WhlYWQAAAM4AAAAMAAAADYPK8YyaGhlYQAAA2gAAAAdAAAAJANCAb1obXR4AAADiAAAACIAAAAiCBkAOGxvY2EAAAOsAAAAFAAAABQBnAIybWF4cAAAA8AAAAAfAAAAIAEVAF5uYW1lAAAD4AAAATAAAAKMFGlj5HBvc3QAAAUQAAAARgAAAHJoedjqd2ViZgAABVgAAAAGAAAABrO7W5UAAAABAAAAANXulPUAAAAA1r4hgAAAAADXu2Q1eNpjYGRgYOABYjEgZmJgBEIOIGYB8xgAA/YAN3jaY2BktGOcwMDKwMI4jTGNgYHBHUp/ZZBkaGFgYGJgZWbACgLSXFMYHFT/fLjFeOD/AQY9xjMMbkBhRpAcAN48DQYAeNpjYGBgZoBgGQZGBhDwAfIYwXwWBgMgzQGETAwMqn8+8H649f8/lHX9//9b7Pzf+fWgusCAkY0BzmUE6gHpQwGMDMMeAACbxg7SAAARAUQAAAAB//8AAnjadZBPSsNAGMXfS+yMqYgOhpSuSlKadmUhiVEhEMQzFF22m17BbbvzCh5BXCUn6EG8gjeQ4DepwYo4i+/ffL95j4EDA+CFC7jQuKyIeVHrI3wkleq9F7XrSInKteOeHdda8bOoaeepSc00NWPz/LRec9G8GabyGtEdF7h19z033GAMTK7zbM42xNEZpzYof0RtQ5CUHAQJ73OtVyutc+3b7Ou//b8XNlsPx3jgjUifABdhEohKJJL5iM5p39uqc7X1+sRQSqmGrUVhlsJ4lpmEUVwyT8SUYtg0P9DyNzPADDs+tjrGV6KRCRfsui3eHcL4/p8ZXvfMlcnEU+CLv7hDykOP+AKTPTxbAAB42mNgZGBgAGKuf5KP4vltvjLIMzGAwLV9ig0g+vruFFMQzdjACOJzMIClARh0CTJ42mNgZGBgPPD/AJD8wgAEjA0MjAyogAMAbOQEAQAAAAC7ABEAAAAAAKoAAAH0AAABgAAAAUAACAFAAAgAwAAXAAAAAAAAACoAKgAqADIAbACGAKAAugDSeNpjYGRgYOBkUGFgYgABEMkFhAwM/xn0QAIADdUBdAB42qWQvUoDQRSFv3GjaISUQaymSmGxJoGAsRC0iPYLsU50Y6IxrvlRtPCJJKUPIBb+PIHv4EN4djKuKAqCDHfmu+feOdwZoMCUAJNbAlYUMzaUlM14jjxbngOq7HnOia89z1Pk1vMCa9x7ztPkzfMyJbPj+ZGi6Xp+omxuPD+zaD7meaFg7mb8GrBqHmhwxoAxlm0uiRkpP9X5m26pKRoMxTGR1D49Dv/Yb/91o6l8qL6eu5n2hZQzn68utR9m3FU2cB4t9cdSLG2utI+44Eh/P9bqKO+oJ/WxmXssj77YkrjasZQD6SFddythk3Wtzrf+UF2p076Udla1VNzsERP3kkjVRKel7mp1udXYcHtZSlV7RfmJe1GiFWveluaeKD5/MuJcSk8Tpm/vvwPIbmJleNpjYGKAAFYG7ICTgYGRiZGZkYWRlZGNkZ2Rg5GTLT2nsiDDEEIZsZfmZRqZujmDaDcDAxcI7WIOpS2gtCWUdgQAZkcSmQAAAAFblbO6AAA=) format('woff');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Dropdown;
    src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAVgAA8AAAAACFAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAchGgaq0dERUYAAAF0AAAAHAAAAB4AJwAPT1MvMgAAAZAAAABDAAAAVnW4TJdjbWFwAAAB1AAAAEsAAAFS8CcaqmN2dCAAAAIgAAAABAAAAAQAEQFEZ2FzcAAAAiQAAAAIAAAACP//AANnbHlmAAACLAAAAQoAAAGkrRHP9WhlYWQAAAM4AAAAMAAAADYPK8YyaGhlYQAAA2gAAAAdAAAAJANCAb1obXR4AAADiAAAACIAAAAiCBkAOGxvY2EAAAOsAAAAFAAAABQBnAIybWF4cAAAA8AAAAAfAAAAIAEVAF5uYW1lAAAD4AAAATAAAAKMFGlj5HBvc3QAAAUQAAAARgAAAHJoedjqd2ViZgAABVgAAAAGAAAABrO7W5UAAAABAAAAANXulPUAAAAA1r4hgAAAAADXu2Q1eNpjYGRgYOABYjEgZmJgBEIOIGYB8xgAA/YAN3jaY2BktGOcwMDKwMI4jTGNgYHBHUp/ZZBkaGFgYGJgZWbACgLSXFMYHFT/fLjFeOD/AQY9xjMMbkBhRpAcAN48DQYAeNpjYGBgZoBgGQZGBhDwAfIYwXwWBgMgzQGETAwMqn8+8H649f8/lHX9//9b7Pzf+fWgusCAkY0BzmUE6gHpQwGMDMMeAACbxg7SAAARAUQAAAAB//8AAnjadZBPSsNAGMXfS+yMqYgOhpSuSlKadmUhiVEhEMQzFF22m17BbbvzCh5BXCUn6EG8gjeQ4DepwYo4i+/ffL95j4EDA+CFC7jQuKyIeVHrI3wkleq9F7XrSInKteOeHdda8bOoaeepSc00NWPz/LRec9G8GabyGtEdF7h19z033GAMTK7zbM42xNEZpzYof0RtQ5CUHAQJ73OtVyutc+3b7Ou//b8XNlsPx3jgjUifABdhEohKJJL5iM5p39uqc7X1+sRQSqmGrUVhlsJ4lpmEUVwyT8SUYtg0P9DyNzPADDs+tjrGV6KRCRfsui3eHcL4/p8ZXvfMlcnEU+CLv7hDykOP+AKTPTxbAAB42mNgZGBgAGKuf5KP4vltvjLIMzGAwLV9ig0g+vruFFMQzdjACOJzMIClARh0CTJ42mNgZGBgPPD/AJD8wgAEjA0MjAyogAMAbOQEAQAAAAC7ABEAAAAAAKoAAAH0AAABgAAAAUAACAFAAAgAwAAXAAAAAAAAACoAKgAqADIAbACGAKAAugDSeNpjYGRgYOBkUGFgYgABEMkFhAwM/xn0QAIADdUBdAB42qWQvUoDQRSFv3GjaISUQaymSmGxJoGAsRC0iPYLsU50Y6IxrvlRtPCJJKUPIBb+PIHv4EN4djKuKAqCDHfmu+feOdwZoMCUAJNbAlYUMzaUlM14jjxbngOq7HnOia89z1Pk1vMCa9x7ztPkzfMyJbPj+ZGi6Xp+omxuPD+zaD7meaFg7mb8GrBqHmhwxoAxlm0uiRkpP9X5m26pKRoMxTGR1D49Dv/Yb/91o6l8qL6eu5n2hZQzn68utR9m3FU2cB4t9cdSLG2utI+44Eh/P9bqKO+oJ/WxmXssj77YkrjasZQD6SFddythk3Wtzrf+UF2p076Udla1VNzsERP3kkjVRKel7mp1udXYcHtZSlV7RfmJe1GiFWveluaeKD5/MuJcSk8Tpm/vvwPIbmJleNpjYGKAAFYG7ICTgYGRiZGZkYWRlZGNkZ2Rg5GTLT2nsiDDEEIZsZfmZRqZujmDaDcDAxcI7WIOpS2gtCWUdgQAZkcSmQAAAAFblbO6AAA=) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Icons;
    src: url(themes/default/assets/fonts/icons.eot);
    src: url(themes/default/assets/fonts/icons.eot?#iefix) format("embedded-opentype"), url(themes/default/assets/fonts/icons.woff2) format("woff2"), url(themes/default/assets/fonts/icons.woff) format("woff"), url(themes/default/assets/fonts/icons.ttf) format("truetype"), url(themes/default/assets/fonts/icons.svg#icons) format("svg");
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-decoration: inherit;
    text-transform: none
}

i.icon {
    display: inline-block;
    opacity: 1;
    margin: 0 .25rem 0 0;
    width: 1.18em;
    height: 1em;
    font-family: Icons;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden
}

i.icon:before {
    background: 0 0 !important
}

i.icon.loading {
    height: 1em;
    line-height: 1;
    animation: b 2s linear infinite
}

i.emphasized.icon, i.icon.active, i.icon.hover {
    opacity: 1 !important
}

i.disabled.icon {
    opacity: .45 !important
}

i.fitted.icon {
    width: auto;
    margin: 0 !important
}

i.link.icon, i.link.icons {
    cursor: pointer;
    opacity: .8;
    transition: opacity .1s ease
}

i.link.icon:hover, i.link.icons:hover {
    opacity: 1 !important
}

i.circular.icon {
    border-radius: 500em !important;
    line-height: 1 !important;
    padding: .5em 0 !important;
    box-shadow: inset 0 0 0 .1em rgba(0, 0, 0, .1);
    width: 2em !important;
    height: 2em !important
}

i.circular.inverted.icon {
    border: none;
    box-shadow: none
}

i.flipped.icon, i.horizontally.flipped.icon {
    transform: scaleX(-1)
}

i.vertically.flipped.icon {
    transform: scaleY(-1)
}

i.clockwise.rotated.icon, i.right.rotated.icon, i.rotated.icon {
    transform: rotate(90deg)
}

i.counterclockwise.rotated.icon, i.left.rotated.icon {
    transform: rotate(-90deg)
}

i.bordered.icon {
    line-height: 1;
    vertical-align: baseline;
    width: 2em;
    height: 2em;
    padding: .5em 0 !important;
    box-shadow: inset 0 0 0 .1em rgba(0, 0, 0, .1)
}

i.bordered.inverted.icon {
    border: none;
    box-shadow: none
}

i.inverted.bordered.icon, i.inverted.circular.icon {
    background-color: #0c3247 !important;
    color: #fff !important
}

i.red.icon {
    color: #850f19 !important
}

i.inverted.red.icon {
    color: #ff695e !important
}

i.inverted.bordered.red.icon, i.inverted.circular.red.icon {
    background-color: #850f19 !important;
    color: #fff !important
}

i.orange.icon {
    color: #e9421e !important
}

i.inverted.orange.icon {
    color: #ff851b !important
}

i.inverted.bordered.orange.icon, i.inverted.circular.orange.icon {
    background-color: #e9421e !important;
    color: #fff !important
}

i.yellow.icon {
    color: #be8c00 !important
}

i.inverted.yellow.icon {
    color: #ffe21f !important
}

i.inverted.bordered.yellow.icon, i.inverted.circular.yellow.icon {
    background-color: #be8c00 !important;
    color: #fff !important
}

i.olive.icon {
    color: #7e922f !important
}

i.inverted.olive.icon {
    color: #d9e778 !important
}

i.inverted.bordered.olive.icon, i.inverted.circular.olive.icon {
    background-color: #7e922f !important;
    color: #fff !important
}

i.green.icon {
    color: #3c8556 !important
}

i.inverted.green.icon {
    color: #2ecc40 !important
}

i.inverted.bordered.green.icon, i.inverted.circular.green.icon {
    background-color: #3c8556 !important;
    color: #fff !important
}

i.teal.icon {
    color: #34867a !important
}

i.inverted.teal.icon {
    color: #6dffff !important
}

i.inverted.bordered.teal.icon, i.inverted.circular.teal.icon {
    background-color: #34867a !important;
    color: #fff !important
}

i.blue.icon {
    color: #407ad5 !important
}

i.inverted.blue.icon {
    color: #2c88a2 !important
}

i.inverted.bordered.blue.icon, i.inverted.circular.blue.icon {
    background-color: #135e72 !important;
    color: #fff !important
}

i.violet.icon {
    color: #562150 !important
}

i.inverted.violet.icon {
    color: #a291fb !important
}

i.inverted.bordered.violet.icon, i.inverted.circular.violet.icon {
    background-color: #562150 !important;
    color: #fff !important
}

i.purple.icon {
    color: #2c2a4c !important
}

i.inverted.purple.icon {
    color: #dc73ff !important
}

i.inverted.bordered.purple.icon, i.inverted.circular.purple.icon {
    background-color: #2c2a4c !important;
    color: #fff !important
}

i.pink.icon {
    color: #6e1932 !important
}

i.inverted.pink.icon {
    color: #ff8edf !important
}

i.inverted.bordered.pink.icon, i.inverted.circular.pink.icon {
    background-color: #6e1932 !important;
    color: #fff !important
}

i.brown.icon {
    color: #995f19 !important
}

i.inverted.brown.icon {
    color: #d67c1c !important
}

i.inverted.bordered.brown.icon, i.inverted.circular.brown.icon {
    background-color: #995f19 !important;
    color: #fff !important
}

i.grey.icon {
    color: #666 !important
}

i.inverted.grey.icon {
    color: #e7eae6 !important
}

i.inverted.bordered.grey.icon, i.inverted.circular.grey.icon {
    background-color: #666 !important;
    color: #fff !important
}

i.black.icon {
    color: #0c3247 !important
}

i.inverted.black.icon {
    color: #545454 !important
}

i.inverted.bordered.black.icon, i.inverted.circular.black.icon {
    background-color: #0c3247 !important;
    color: #fff !important
}

i.mini.icon, i.mini.icons {
    line-height: 1;
    font-size: .4em
}

i.tiny.icon, i.tiny.icons {
    line-height: 1;
    font-size: .5em
}

i.small.icon, i.small.icons {
    line-height: 1;
    font-size: .75em
}

i.icon, i.icons {
    font-size: 1em
}

i.large.icon, i.large.icons {
    line-height: 1;
    vertical-align: middle;
    font-size: 1.5em
}

i.big.icon, i.big.icons {
    line-height: 1;
    vertical-align: middle;
    font-size: 2em
}

i.huge.icon, i.huge.icons {
    line-height: 1;
    vertical-align: middle;
    font-size: 4em
}

i.massive.icon, i.massive.icons {
    line-height: 1;
    vertical-align: middle;
    font-size: 8em
}

i.icons {
    display: inline-block;
    position: relative;
    line-height: 1
}

i.icons .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0
}

i.icons .icon:first-child {
    position: static;
    width: auto;
    height: auto;
    vertical-align: top;
    transform: none;
    margin-right: .25rem
}

i.icons .corner.icon {
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
    transform: none;
    font-size: .45em;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff
}

i.icons .top.right.corner.icon {
    top: 0;
    left: auto;
    right: 0;
    bottom: auto
}

i.icons .top.left.corner.icon {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto
}

i.icons .bottom.left.corner.icon {
    top: auto;
    left: 0;
    right: auto;
    bottom: 0
}

i.icons .bottom.right.corner.icon {
    top: auto;
    left: auto;
    right: 0;
    bottom: 0
}

i.icons .inverted.corner.icon {
    text-shadow: -1px -1px 0 #0c3247, 1px -1px 0 #0c3247, -1px 1px 0 #0c3247, 1px 1px 0 #0c3247
}

.ui.table {
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34, 36, 38, .15);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: .28571429rem;
    text-align: left;
    color: rgba(0, 0, 0, .87);
    border-collapse: separate;
    border-spacing: 0
}

.ui.table:last-child {
    margin-bottom: 0
}

.ui.table td, .ui.table th {
    -webkit-transition: background .1s ease, color .1s ease;
    transition: background .1s ease, color .1s ease;
    vertical-align: top
}

.ui.table td ul {
    margin: 0
}

.ui.table thead {
    -webkit-box-shadow: none;
    box-shadow: none
}

.ui.table thead th {
    cursor: auto;
    background: #f9fafb;
    text-align: inherit;
    color: rgba(0, 0, 0, .87);
    padding: .92857143em .78571429em;
    vertical-align: inherit;
    font-style: none;
    font-weight: 700;
    text-transform: none;
    border-bottom: 1px solid rgba(34, 36, 38, .1);
    border-left: none
}

.ui.table thead tr>th:first-child {
    border-left: none
}

.ui.table thead tr:first-child>th:first-child {
    border-radius: .28571429rem 0 0 0
}

.ui.table thead tr:first-child>th:last-child {
    border-radius: 0 .28571429rem 0 0
}

.ui.table thead tr:first-child>th:only-child {
    border-radius: .28571429rem .28571429rem 0 0
}

.ui.table tr td {
    border-top: 1px solid rgba(34, 36, 38, .1)
}

.ui.table tr:first-child td {
    border-top: none
}

.ui.table td {
    padding: .78571429em .78571429em;
    text-align: inherit
}

@media only screen and (max-width:767px) {
    .ui.table:not(.unstackable) {
        width: 100%
    }
}

.ui.table:not(.unstackable) tbody, .ui.table:not(.unstackable) tr, .ui.table:not(.unstackable) tr>td, .ui.table:not(.unstackable) tr>th {
    width: auto !important;
    display: block !important
}

.ui.table:not(.unstackable) {
    padding: 0
}

.ui.table:not(.unstackable) thead {
    display: block
}

.ui.table:not(.unstackable) tr {
    padding-top: 1em;
    padding-bottom: 1em;
    -webkit-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .1) inset !important;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .1) inset !important
}

.ui.table:not(.unstackable) tr>td, .ui.table:not(.unstackable) tr>th {
    background: 0 0;
    border: none !important;
    padding: .25em .75em !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.ui.table:not(.unstackable) td:first-child, .ui.table:not(.unstackable) th:first-child {
    font-weight: 700
}

.ui.table [class*="right aligned"] {
    text-align: right
}

.ui.table td.collapsing {
    width: 1px;
    white-space: nowrap
}

.ui.striped.table tbody tr:nth-child(2n) {
    background-color: rgba(0, 0, 50, .02)
}

.ui.celled.table tr td, .ui.celled.table tr th {
    border-left: 1px solid rgba(34, 36, 38, .1)
}

.ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child {
    border-left: none
}

.ui.table {
    font-size: 1em
}

.ui.breadcrumb:first-child {
    margin-top: 0
}

.ui.breadcrumb {
    font-size: 1rem
}

.ui.breadcrumb {
    line-height: 1;
    display: inline-block;
    margin: 0 0;
    vertical-align: middle
}

.ui.breadcrumb .icon.divider {
    font-size: .85714286em;
    vertical-align: baseline
}

.ui.breadcrumb .divider {
    display: inline-block;
    opacity: .7;
    margin: 0 .21428571rem 0;
    font-size: .92857143em;
    color: rgba(0, 0, 0, .4);
    vertical-align: baseline
}

.ui.breadcrumb .active.section {
    font-weight: 700
}

.ui.breadcrumb .section {
    display: inline-block;
    margin: 0;
    padding: 0
}

i.icon.angle.right:before {
    content: "\f105"
}

i.icon.handshake:before {
    content: "\f2b5"
}

.disabled, .disabled a {
    color: #CCC !important;
    pointer-events: none;
}

i.icon.exclamation.circle:before {
    content: "\f06a";
}

.ui.form {
    padding: 1rem;
    margin: 0 auto 0 0;
    max-width: 768px;
}

.ui.form input[type=checkbox], .ui.form textarea {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

h5 {
    font-size: 1rem;
    margin: 0 0 .5rem 0;
    font-size: .92857143em;
}

.banner {
    margin-top: 4rem;
    background-color: #8231b6;
    background-image: linear-gradient(to right, #8231b6, #2f79dc);
    position: relative;
}

.banner p {
    padding: 1rem;
    color: white;
    padding-right: 11rem;
}

.banner a {
    color: white;
    text-decoration: underline;
    font-weight: 700;
}
.ui.button {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2 none;
    color: rgba(0,0,0,.6);
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em .78571429em;
    text-transform: none;
    text-shadow: none;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none !important;
    border-radius: .28571429rem;
}
.ui.purple.button {
    background-color: #8231b6;
    color: #fff;
    text-shadow: none;
    background-image: none;
    transition: background-color .25s;
}
.ui.purple.button:hover {
    background-color: #7221a6;
}
.banner .ui.button {
    position: absolute;
    top: .35rem;
    right: 1rem;
    margin: 0;
}
#processing_label a:hover {
    text-decoration: underline;
}

.portal .card ol {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    margin-block-start: 0;
}

.portal .card ol li {
    margin-block-end: 0.5rem;
}

.portal .card .button {
    background: #000;
    color: #FFF;
    margin: .5rem 0 0 .5rem;
    display: inline-block;
}

@media only screen and (min-width:1099px) {
    .portal .card .button {
        margin: .5rem 0 0 0;
        display: block;
    }
}

.portal .card .button .icon {
    height: 0.785714285714286rem;
    margin: -3px 0 0 0;
    display: inline-block;
}

i.icon.database:before {
    content: "\f1c0";
}

.portal .card .button.blue,
.portal .card .button.grey {
    background: #2F79DC;
    font-size: 0.857142857142857rem;
    text-align: center;
}

.portal .card .button.grey {
    background: #DDDDDD;
}

.portal .card .button.purple {
    background: #8231b6;
}

.portal .card .button:hover {
    opacity: .9;
}

.portal .card .button .icon {
    display: block;
    margin: .2rem auto .5rem auto;
    font-size: 1.2rem;
}

.portal .card .button.grey,
.portal .card .button.grey .icon {
    color: #2F79DC;
}
.hero-content {
    display: flex;
}

.hero-child {
    flex: 1;
}

.hero-ul {
    list-style-type: none;
}

.hero-ul > li:before {
    content: "–"; /* en dash */
    position: absolute;
    margin-left: -1.1em;
}
