body {
	height: calc(100vh - 150px);
	background: linear-gradient(to bottom left, #002855, #0057a8);
	background-attachment: fixed;
}

a {
	font-weight: bold;
}

#lead-text {
	font-size: 1.15rem;
}

.ui.button.overview_toggle {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	font: inherit;
	color: #ccc;
}

.ui.button.overview_toggle>i {
	font-size: 2rem;
}

.ui.button.overview_toggle:hover {
	color: #fff;
}

.ui.button.overview_toggle>.icon.left {
	margin: 0;
	float: left;
}

.ui.button.overview_toggle>.icon.right {
	margin: 0;
	float: right;
}

.ui.overview_toggle button .icon {
	margin: 0
}

button.item {
	width: 100%;
}

.ui.inverted.menu .item,
.ui.inverted.menu .item>a:not(.ui) {
	color: #fff;
}

.ui.nav .item>.knowledgebase .icon {
	color: #fff;
}

.ui.inverted.menu .dropdown.item:hover,
.ui.inverted.menu a.item:hover {
	color: #fff
}

.ui.inverted.menu .dropdown.item>img.icon,
.ui.inverted.menu a.item>img.icon {
	opacity: 1
}

.ui.inverted.menu .dropdown.item:hover>img.icon,
.ui.inverted.menu a.item:hover>img.icon {
	opacity: 0;
}

.ui.inverted.menu .dropdown.item>img.icon.hover,
.ui.inverted.menu a.item>img.icon.hover {
	position: absolute;
	opacity: 0
}

.ui.inverted.menu .dropdown.item:hover>img.icon.hover,
.ui.inverted.menu a.item:hover>img.icon.hover {
	opacity: 1;
}

.ui.nav .item>.knowledgebase:focus .icon,
.ui.nav .item>.knowledgebase:hover .icon {
	color: #fff;
}

.ui.menu .dropdown.item .menu {
	background: #fff;
	border-radius: 0 0 1.5rem 1.5rem;
	margin: 0 .5rem;
	padding-bottom: .5rem;
	min-width: calc(100% - 1rem)
}

.ui.menu .ui.dropdown .menu>.item {
	color: #002855 !important;
}

.ui.menu .ui.dropdown .menu>.item:hover {
	color: #005eb8 !important;
}

.ui.nav .ui.dropdown .menu>.item:focus,
.ui.nav .ui.dropdown .menu>.item:hover {
	background: rgba(0, 0, 0, 0) !important;
}

.ui.card a.workspace-name::after {
	color: #0a518c;
}


.portal.page.content {
	display: flex;
}

.portal.page .ui.card>.content.header {
	border-color: #0a518c;
	border-image-source: linear-gradient(to right, #ffcf4d, #f4c500);
	border-left: 0px solid;
	border-right: 0px solid;
	border-bottom: 0px solid;
	border-top: 5px solid !important;
}

.banner {
	background-color: #0c3247;
	background-image: linear-gradient(to left, #ffcf4d, #e58e03);
}

.avatar-initials {
	color: #fff;
}

.overview {
	background: #0a518c;
	display: flex;
}

.overview-footnote {
	font-weight: normal;
	font-size: 0.8em;
	color: rgba(255, 255, 255, .8);
	font-style: italic;
}

.overview .ui.image {
	margin-top: 2rem;
	margin-bottom: 2rem;
	margin-right: 0px;
	margin-left: 2%;
	width: 95%;
	border-radius: 2.5%;
}

.overview .ui.image {
	margin: 2rem 0;
}

.overview .ui.image-container {
	width: 100%;
	text-align: right;
}

i.icon.eye-dropper:before {
	content: "\f1fb"
}

i.icon.folder:before {
	content: "\f07b"
}

i.icon.folder-plus:before {
	content: "\f65e"
}

i.icon.question-circle:before {
	content: "\f059"
}

i.icon.database:before {
	content: "\f1c0"
}

i.icon.project-diagram:before {
	content: "\f542"
}

i.icon.cog:before {
	content: "\f013"
}

i.icon.key:before {
	content: "\f084";
}

i.icon.graduation:before {
	content: "\f19d";
}

i.icon.life-ring:before {
	content: "\f1cd";
}

i.icon.desktop:before {
	content: "\f108";
}

i.icon.blue {
	color: #1073b9 !important;
}

.ui.purple.button {
	background-color: #0a518c;
}

.ui.purple.button:hover {
	background-color: #1073b9;
}

.ui.purple.button.CPTA {
	background-color: #f5f5f5;
	color: #777;
	font-weight: normal;
	padding: 0.5rem;
	padding-bottom: 0.2rem;
	float: left;
	width: calc(33.3% - .5rem);
	margin-right: .5rem;
	min-width: 100px;
}

.ui.purple.button.CPTA div:nth-child(2) {
	padding: .5rem;
	line-height: 1.2em;
}

.ui.purple.button.CPTA:hover {
	background-color: #e5e5e5;
	color: #0a518c;
}

[data-position~=bottom][data-tooltip]:before {
	background: #ffffff;
}

[data-tooltip]:after,
[data-tooltip]:before {
	background: #ffffff;
}

[data-tooltip]:after {
	border: 0px solid #000000;
	color: #000;
}

.banner .ui.button {
	top: calc(50% - 11px - 0.5em);
}

.banner-container {
	margin-top: 4rem;
}

/* Overwriting some semantic css  */
.portal .card .ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
	margin: .2rem auto .5rem auto;
}

i.icon.loading {
	animation: icon-loading 2s linear infinite;
}

.portal .card .button {
	background: #1073b9;
	display: block
}

.portal .card .button .icon-inline {
	display: inline-block;
	height: 1rem;
	padding-right: 0.3rem;
}

.portal .card .button .icon-inline-ws {
	height: 1.6rem;
}

@media only screen and (min-width: 1200px) {
	.ui.container {
		width: auto;
	}
}

.portal .ui.card>.content>.need-help.description {
	padding: .5rem;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.need-help.description>div {
	flex: 1 0 30%;
	background: none;
	margin: .5em;
	padding: 1em;
	border-radius: 3px;
	display: grid
}

.need-help.description>div>a {
	display: grid
}

.need-help.description>div>a>i {
	display: contents;
}

a:hover>i.icon.blue {
	color: #0a518c !important;
}

@media screen and (min-width:1280px) {
	.need-help.description>div {
		flex: 1;
	}
}

.need-help.description>div>i {
	margin: auto;
	max-height: 60px
}

i.x-large.icon {
	line-height: 1;
	vertical-align: middle;
	font-size: 2.5em;
}

#portalLogo {
	width: 140px;
	;
}

#portalLogoHover {
	position: absolute;
	width: 140px;
	;
}

#overviewLogo {
	width: 100%;
	display: block;
	margin: 0 auto 1em;
	border-radius: 1.5rem;
}

#header .ui.menu .container>a.item:first-child {
	width: 175px;
}

#header .ui.menu .container>a.item:first-child>#portalLogo {
	opacity: 1;
}

#header .ui.menu .container>a.item:first-child>#portalLogoHover {
	opacity: 0;
}

#header .ui.menu .container>a.item:first-child:hover>#portalLogo {
	opacity: 0;
}

#header .ui.menu .container>a.item:first-child:hover>#portalLogoHover {
	opacity: 1;
}

.aridhia-alert {
	margin-top: 4rem;
}

#announcement:not(:empty)+#body #sidebar {
	top: 127px;
}

#announcement:not(:empty)+#body .rsh-rtools-header .rsh-console-button {
	top: 159px;
}

#announcement {
	z-index: 99;
	position: relative;
}

#announcement .aridhia-alert {
	height: 70px;
	background: #135e72;
	position: relative;
	padding: 0.35714286rem;
}

#announcement .aridhia-alert .ui.message {
	box-sizing: border-box;
	align-items: flex-start;
	min-height: 70px;
	overflow: hidden;
}

#announcement .aridhia-alert .ui.message>.icon:not(.close) {
	color: #666564;
	font-size: 2.28571429rem;
	margin-top: 0.14285714rem;
}

#announcement .aridhia-alert .ui.message>.content {
	height: 2.5rem;
	max-width: calc(93.5%);
	margin: 0;
}

#announcement .aridhia-alert .ui.message>.content .header {
	color: #135e72;
	margin: 0;
}

#announcement .aridhia-alert .ui.message>.content .header .aridhia-date {
	display: inline-block;
	font-size: 0.92857143rem;
	margin-left: 0.92857143rem;
	font-style: italic;
	color: rgba(5, 5, 5, 0.5);
	border-left: 1px solid rgba(5, 5, 5, 0.33);
	padding-left: 0.85714286rem;
	white-space: nowrap;
	max-height: 1.42857143rem;
}

#announcement .aridhia-alert .ui.message>.content .text {
	height: 20px;
}

#announcement .aridhia-alert .ui.message>.content .text * {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 1rem;
}

#announcement .aridhia-alert .ui.message>.content .text p {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#announcement .aridhia-alert .ui.message>.content .text table {
	text-align: left;
}

#announcement .aridhia-alert .ui.message>.content .text a {
	color: #135e72;
	font-weight: 700;
}

#announcement .aridhia-alert .ui.message .aridhia-count {
	position: absolute;
	right: 35px;
	top: 11px;
	color: #135e72;
	font-size: 0.92857143rem;
	font-weight: 700;
}

#announcement .aridhia-alert .ui.message .ui.pagination.menu {
	position: absolute;
	right: 5px;
	top: 35px;
	background: 0;
	border: 0;
}

#announcement .aridhia-alert .ui.message .ui.pagination.menu>.item {
	color: #135e72;
	font-family: 'Icons';
	padding: 5px;
	min-width: auto;
	opacity: 0.75;
}

#announcement .aridhia-alert .ui.message .ui.pagination.menu>.item:hover {
	opacity: 1;
	background: 0;
}

#announcement .aridhia-alert .ui.message .ui.pagination.menu>.item::before {
	display: none;
}

#announcement .aridhia-alert .ui.message:hover {
	max-height: calc(100vh - 100px);
}

#announcement .aridhia-alert .ui.message:hover>.content {
	height: auto;
}

#announcement .aridhia-alert .ui.message:hover>.content .header {
	height: auto;
	white-space: initial;
	overflow: initial;
}

#announcement .aridhia-alert .ui.message:hover>.content .text {
	height: auto;
	max-height: calc(100vh - 165px);
	overflow: auto;
}

#announcement .aridhia-alert .ui.message:hover>.content .text p {
	white-space: initial;
	overflow: initial;
}

.app-page-fullscreen #announcement {
	display: none;
}

#header {
	z-index: 100;
}

.powered-by-aridhia {
	max-width: 200px;
	opacity: 0.5;
	margin-bottom: 20px;
	float: right;
}

.form-input-stack-items>div {
	display: block !important;
}


.required {
	margin: -0.2em 0em 0em 0.2em;
	content: '*';
	color: #DB2828;
}

/*--Bit of a style Overhaul--*/

.ui.nav.inverted.menu {
	background: #005eb8;
	border-bottom: none;
	box-shadow: 0 0 30px #002855
}

#header .ui.menu .container>a.item.menu {
	padding: 0 2rem;
	background: none;
	width: auto
}

#header .ui.menu .container>a.item.menu:hover {
	padding: 0 2rem;
	background: #002855;
	width: auto
}

.ui.container>.header.item.logo {
	height: 5.5em;
	border-radius: 0 0 1.5rem 1.5rem !important;
	background: #fff;
	margin-left: 5rem
}

.ui.container>.header.item.logo:hover {
	background: #002855;
	opacity: 1
}

.ui.inverted.menu .item.logo:before {
	display: none
}

.overview {
	background: #005eb8;
	color: #fff;
	border: none;
	padding: 2rem;
	margin-bottom: 11rem;
	margin-left: 1rem;
	border-radius: 1.5rem
}

.overview-hidden>.overview {
	background: #80AEDB;
	margin-bottom: 11rem;
	border-radius: 0;
	border-radius: 0 1.5rem 1.5rem 0
}

.ui.button>.icon:not(.button) {
	opacity: 1;
}

.ui.button.overview_toggle {
	color: #fff
}

.ui.button.overview_toggle:hover {
	color: #002855
}

.overview h2.ui.header {
	color: #BFD7ED;
}

.overview a {
	color: #FEC52E;
	transition: 0.2s;
}

.overview a:hover {
	color: #fff;
	transition: 0.2s;
}

.overview-footnote {
	color: #fff;
}

#flex {
	background: none;
	padding-top: 4rem;
	padding-bottom: 1rem;
	color: #fff
}

.ui.segment {
	padding: 2em
}

@media only screen and (max-width: 1199.5px) {
	#flex .ui.container {
		width: auto !important;
	}
}

@media only screen and (min-width: 1200px) {
	#flex .ui.container {
		max-width: 1180px !important;
	}
}

.ui.fluid.card {
	box-shadow: none;
	background: none
}

.ui.fluid.card .content {
	background: #fff;
	border-radius: 0 0 1.5rem 1.5rem !important
}

.portal.page .ui.card>.content.header {
	border: none;
	border-top: none !important;
	padding: 1.25em;
	max-height: 4rem;
	background: #005eb8;
	border-radius: 1.5rem 1.5rem 0 0 !important
}

.portal.page .ui.card>.content.header a {
	color: #fff;
	font-size: 1.2em
}

.ui.card>.content.header .workspace-name {
	color: #fff;
}

.ui.card a.workspace-name::after {
	color: #fff;
}

.card img.right.floated.icon.fair {
	height: 1.2rem
}

.ui.container h2.ui.header {
	color: #BFD7ED
}

#lead-text a {
	color: #FEC52E;
	transition: 0.2s;
}

#lead-text a:hover {
	color: #fff;
	transition: 0.2s;
}

.portal .card .button {
	background: #005eb8;
	height: 4rem;
	border-radius: 2rem;
	padding: 1.2rem;
	margin: .75rem -.5rem;
	transition: 0.2s;
}

.portal .card .button:hover {
	background: #002855;
	opacity: 1;
	transition: 0.2s;
}

.portal .card .button .icon-inline-ws {
	height: 1.4rem
}

.portal .card .button.image {
	padding: 0;
	margin: .5rem;
	width: calc(33.3% - 1rem);
	overflow: hidden;
	height: auto;
	border-radius: 0.5rem;
	color: #fff;
	background: #002855
}

.portal .card .button.image:hover {
	background: #005eb8;
	color: #fff
}

.ui.card .content img {
	opacity: .95
}

.ui.card .content img:hover {
	opacity: 1
}

.need-help.description>div {
	padding: .5em
}

.ui.fluid.card .content.square-corners {
	border-radius: 0 !important
}

.ui.fluid.card .content h5 {
	color: rgba(0, 0, 0, 0.87)
}

.ui.fluid.card .content p {
	color: rgba(0, 0, 0, 0.87)
}

.portal .card .button.form {
	margin-left: .5rem;
	width: 8rem
}

.hidden {
	display: none;
}

.ui.breadcrumb .divider {
    color: #fff;
}

.qs4-container {
	display:flex;
	align-items: stretch;
	gap: 1rem
}

.qs4-image {
	background: url('../images/CPTA-image.jpg');
	background-size: cover;
	background-position: right;
	border-radius: .75rem;
	overflow: hidden;
	flex-basis: 100%
}